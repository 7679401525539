import React from "react";
import { FiEdit } from "react-icons/fi";
import { PiListMagnifyingGlass } from "react-icons/pi";
import { TbListCheck } from "react-icons/tb";
import { useEffect } from "react";

const AuditandAssur = () => {
  return (
    <div className="bg-white text-black xl:px-[2rem]">
      {/* -------------------------------Audit and Asurance ------------------------*/}
      <div className="p-5 max-w-screen ">
        <div className="lg:w-[60vw] ">
          <h1 className="text-black lg:mt-10 mt-20  mb-6 block text-base font-semibold  font-serif"></h1>
          <p className="lg:text-3xl font-extrabold text-3xl text-black ">
            Audit and Assurance
          </p>

          <p className="pt-4 pb-[2rem] lg:pb-[4rem] lg:text-lg font-san font-semibold text-lg text-black ">
            Audit and assurance activities aim to enhance confidence in the
            integrity and transparency of information for stakeholders
          </p>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 px-[0.9rem]">
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 lg:border-r-2 border-gray-400">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <TbListCheck size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">Audit</p>
              <p className="text-base text-left pr-[1rem] ">
                We will provide assurance that the financial statements present
                a true and fair view of the organization's financial position,
                performance, and cash flows in accordance with generally
                accepted accounting principles (GAAP) or another applicable
                financial reporting framework.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 lg:border-r-2 border-gray-400">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <PiListMagnifyingGlass size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">Review</p>
              <p className="text-base text-left">
                We will provide limited assurance that there are no material
                modifications needed to the financial statements for them to be
                in conformity with generally accepted accounting principles
                (GAAP) or another applicable financial reporting framework.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700  ">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <FiEdit size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">
                Compilation
              </p>
              <p className="text-base text-left">
                We will prepare financial statements based on information
                provided by a company's management. Unlike an audit or review, a
                compilation does not involve any assurance on the accuracy or
                completeness of the financial statements. The purpose of a
                compilation is primarily for the organization's management to
                have financial statements prepared in a structured format for
                internal use or to meet basic reporting requirements.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditandAssur;
