import React from "react";
import pexels_fauxels_3183156 from "./Asset/pexels_fauxels_3183156.jpg";
import pexels_hillaryfox_1595390 from "./Asset/pexels_hillaryfox_1595390.jpg";
import pexels_tima_miroshnichenko_6693655 from "./Asset/pexels_tima_miroshnichenko_6693655.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const HomeIndustries = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const buttonHandler = () => {
    navigate("/industries");
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="bg-[white]">
      <div className="flex flex-col justify-center items-center gap-y-5 px-10">
        {/*------------------------------------------------*/}
        <h1 className="mt-10 text-center font-bold text-[2.5rem] text-black">
          Industries
        </h1>

        <p className="text-center xl:w-[58vw]  ">
          We have deep industry knowledge and experience in various sectors,
          such as healthcare, real estate, and small business. We understand the
          challenges and opportunities that each industry faces, and we provide
          customized solutions to help you achieve your objectives.
        </p>

        <button
          onClick={buttonHandler}
          className="mb-10 mt-4  bg-[#232960] saturate-50 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
        >
          Read More
        </button>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 w-[70vw]">
          {/*-----------------------Health Care-------------------------*/}
          <div className="hover:scale-105 hover:duration-300  rounded overflow-hidden shadow-lg bg-white mb-10">
            <div className="flex justify-center items-center p-5 object-cover ">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="70"
                height="70"
                fill="currentColor"
                className="bi bi-hospital"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25z" />
                <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1zm2 14h2v-3H7zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm0-14H6v1h4zm2 7v7h3V8zm-8 7V8H1v7z" />
              </svg>
            </div>
            <div className="px-6 py-4 text-center">
              <div className="font-bold text-xl mb-2">Healthcare</div>
              <p className="text-gray-700 text-base mt-[1.4rem] ">
                Hospital and Clinic
              </p>
              <p className="text-gray-700 text-base">Mental Health Center</p>
              <p className="text-gray-700 text-base mb-4">Nursing Home</p>
            </div>
          </div>

          {/*------------------------------------------------*/}
          <div className="hover:scale-105 hover:duration-300  rounded overflow-hidden shadow-lg bg-white mb-10">
            <div className="flex justify-center items-center p-5 object-cover ">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="70"
                height="70"
                fill="currentColor"
                className="bi bi-houses"
                viewBox="0 0 16 16"
              >
                <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207z" />
              </svg>
            </div>
            <div className="px-6 py-4 text-center">
              <div className="font-bold text-xl mb-2">Real Estate</div>
              <p className="text-gray-700 text-base mt-[1.4rem]">
                Property Owner
              </p>
              <p className="text-gray-700 text-base">
                Property Management Company
              </p>
              <p className="text-gray-700 text-base mb-4">
                Construction Company
              </p>
            </div>
          </div>
          {/*------------------------------------------------*/}
          <div className="hover:scale-105 hover:duration-300   rounded overflow-hidden shadow-lg bg-white mb-10">
            <div className="flex justify-center items-center p-5 object-cover ">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="70"
                height="70"
                fill="currentColor"
                className="bi bi-briefcase"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
              </svg>
            </div>
            <div className="px-6 py-4 text-center">
              <div className="font-bold text-xl mb-2">Small Business</div>
              <p className="text-gray-700 text-base mt-[1.4rem]">IT</p>
              <p className="text-gray-700 text-base">Startup</p>
              <p className="text-gray-700 text-base  mb-4">Various</p>
            </div>
          </div>
          {/*------------------------------------------------*/}
        </div>
      </div>
    </div>
  );
};

export default HomeIndustries;
