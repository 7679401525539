import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
/*
 * Change CopyRight & fix
 */
const Footer = () => {
  const instagramURL = "https://www.instagram.com/jeoncpas/";
  const emailAddress = "jjeon@jeoncpas.com";

  const [isClicked, setIsClicked] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      scrollTop: 0,
      behavior: "smooth",
    });
    setIsClicked(!isClicked);
  };

  return (
    <footer className="bg-white ">
      <div className="flex justify-center items-center mt-10">
        <p className="border-gray-200 border-b-2 w-[60vw]"></p>
      </div>
      <div className="flex justify-center mx-auto w-full max-w-screen-xl">
        <div className="grid lg:flex lg:gap-x-[10rem] grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
              SERVICES
            </h2>
            <ul className="text-gray-500  font-medium">
              <li className="mb-4">
                <Link
                  to="/service?section=auditandassurance"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  Audit and Assurance
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/service?section=tax"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  Tax
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/service?section=advisory"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  Advisory
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
              INDUSTRIES
            </h2>
            <ul className="text-gray-500  font-medium">
              <li className="mb-4">
                <Link to="/industries#healthcare" className="hover:underline">
                  Healthcare
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/industries#realEstate" className="hover:underline">
                  Real Estate
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/industries#smallBusiness"
                  className="hover:underline"
                >
                  Small Business
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
              COMPANY
            </h2>
            <ul className="text-gray-500  font-medium">
              <li className="mb-4">
                <Link
                  to="/howwework"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  How We Work
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/careers"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  Careers
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="contactus"
                  className="hover:underline"
                  onClick={scrollToTop}
                >
                  Contact Us
                </Link>
              </li>
              <div></div>
            </ul>
          </div>
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
              More
            </h3>

            <ul className="text-gray-500 font-medium">
              <Link
                onClick={() => {
                  window.location = "mailto:danielycheon@gmail.com";
                }}
              >
                <li className="mb-4 hover:underline">Email</li>
              </Link>
              <Link to={instagramURL} className="hover:underline">
                <li className="mb-4 hover:underline"> Instagram</li>
              </Link>
              <a href="tel:+13237081555" className="phone-link">
                <span className="phone-text hover:underline">Phone</span>
              </a>
            </ul>
          </div>
        </div>
        {/*------------------- SNS Links */}
      </div>
    </footer>
  );
};

export default Footer;
