import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import JeonAssociateLogo from "../Asset/JeonAssociateLogo.png";
/*
<nav className=" bg-white border-gray-200 dark:bg-gray-900 max-w-screen opacity-40">
      <div className="flex flex-1 items-center justify-between sm:items-stretch mx-auto p-4 bg-blue-400">
        <Link to="/">
          <h1 className="font-bold text-2xl">J E O N & P.C.</h1>
        </Link>
        
        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse   dark:bg-gray-800  dark:border-gray-700">
          <NavLink
            to="/service"
            className="sm:text-black block py-2 px-2  bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
            style={({ isActive }) => ({
              background: isActive ? "orange" : "white",
            })}
          >
            Service
          </NavLink>

          <NavLink
            to="/industries"
            className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
            style={({ isActive }) => ({
              background: isActive ? "orange" : "white",
            })}
          >
            Industries
          </NavLink>
          <NavLink
            to="/howwework"
            className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
            style={({ isActive }) => ({
              background: isActive ? "orange" : "white",
            })}
          >
            How We Work
          </NavLink>
          <NavLink
            to="/careers"
            className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
            style={({ isActive }) => ({
              background: isActive ? "orange" : "white",
            })}
          >
            Careers
          </NavLink>
          <NavLink
            to="/contactus"
            className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
            style={({ isActive }) => ({
              background: isActive ? "orange" : "white",
            })}
          >
            Contact Us
          </NavLink>
        </ul>
      </div>
    </nav>*/

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const toggleNavbar = () => {
    if (isOpen === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };
  const triggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    console.log("click");
  };

  const closeNavbar = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsOpen(false);
    setIsDropdownOpen(false);
  };
  const showMenu = () => {
    setIsDropdownOpen(true);
  };
  const hideMenu = () => {
    setIsDropdownOpen(false);
  };
  const mobileShowMenu = () => {
    if (isDropdownOpen === false) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  return (
    <div>
      <nav className="bg-white fixed lg:static overflow-visible top-0 w-full  border-gray-200  z-10 ">
        <div className="text-[#232960] max-w-screen-xl flex flex-wrap md:flex-col lg:flex-row items-center justify-between mx-auto p-6">
          <div className="">
            <Link to="/" onClick={closeNavbar}>
              <div className="flex">
                <img
                  src={JeonAssociateLogo}
                  alt="logo"
                  className="w-[17rem] lg:w-[17rem] h-[2rem]"
                />
              </div>
            </Link>
          </div>
          <button
            onClick={toggleNavbar}
            type="button"
            className=" inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-[#2e1065] rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-default"
            aria-expanded={isOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-normal flex flex-col  md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
              <li>
                <NavLink
                  to="/"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3 rounded md:bg-transparent md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                    /*
                     *
                     * Formula: background: isActive ? "TRUE" : "FALSE",
                     * example: background: isActive ? "orange" : "white",
                     * If Menu is active then background color is "orange" else "white"
                     */
                  })}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/service"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3   rounded md:bg-transparent md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                  })}
                >
                  Services
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/industries"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3  0 rounded md:bg-transparent md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                  })}
                >
                  Industries
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/howwework"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3   rounded md:bg-transparent  md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                  })}
                >
                  How We Work
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/careers"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3   rounded md:bg-transparent  md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                  })}
                >
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactus"
                  onClick={closeNavbar}
                  className="font-semibold block py-2 px-3   rounded md:bg-transparent  md:p-0  "
                  style={({ isActive }) => ({
                    background: isActive ? "" : "",
                  })}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
