import React from "react";
import { useState, useEffect } from "react";
import kakaotalk_sharing_btn from "./Asset/kakaotalk_sharing_btn.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Instagram_Glyph_Gradient from "./Asset/Instagram_Glyph_Gradient.png";
import emailjs from "emailjs-com";
import { useRef } from "react";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  /**
   * Key Implementation Points:
        1. Check if the name is empty.
        2. Check email validation. @ and .com .net .org
        3. Check if the message is empty.
      
   */
  const instagramURL = "https://www.instagram.com/jeoncpas/";

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Open menu for Company size
  const [selectedOption, setSelectedOption] = useState("Select Company Size");
  const [textAreaOnchage, setTextAreaOnchange] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const SERVICE_ID = "service_1nlw70f";
  const TEMPLATE_ID = "template_avvay0x";
  const PUBLIC_KEY = "uVFjJNsBdS18CNr2X";

  const dropdownOptions = [
    { label: "1-10 employees", value: "1-10" },
    { label: "11-50 employees", value: "11-50" },
    { label: "51-200 employees", value: "51-200" },
    { label: "201-1000 employees", value: "201-1000" },
    { label: "Enterprise (1001+ employees)", value: "1001+" },
  ];

  const form = useRef();

  /*Send Email Handler */
  const sendEmailHandlerFunction = (event) => {
    event.preventDefault();
    console.log("Form Data:", formData);

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        alert("Email Sent Successfully");
      })
      .catch((error) => {
        console.log(error.text);
        alert("Email Sent Failed");
      });

    event.target.reset();
  };

  const MenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setFormData((prevFormData) => ({
      ...prevFormData,
      user_company_size: option.value,
    }));
    setIsMenuOpen(false);
  };

  /*----  Function to input only integer  ----*/
  const onlyInteger = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    if (!isNaN(inputValue)) {
      setValue(inputValue);
    }
  };
  /*
  
   * Implement the email including @ and .com .net .org
   * Implement the message section to check if the message is empty.
   * Implement the name section to check if the name is empty.
   * Implement the company name section to check if the company name is empty.
   * Implement the company size section to check if the company size is empty.
   * Implement the dropdown menu to select the company size.
   */

  const [formData, setFormData] = useState({
    user_name: "",
    user_company_name: "",
    user_company_size: "",
    message: "",
    user_email: "",
    user_phone: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="flex-col flex">
      <div className="lg:pt-[0rem] pt-16 mt-[4rem]  px-[2rem] 2xl:px-[20rem]">
        <div className="flex flex-col text-center w-full">
          <h1 className="title-font text-gray-900 font-extrabold text-4xl">
            Contact Us
          </h1>
          <p className="text-lg font-semibold text-left mt-[3rem] mb-[0rem] 2xl:px-[0rem] xl:px-[10rem] lg:px-[6rem] px-[1rem]">
            Our commitment to you goes beyond delivering high quality accounting
            services. We want to build a lasting relationship with you and
            support your success and growth as your long-term partner. We are
            here to stay with you and help you achieve your vision. Contact us
            today to find out how we can help you!
          </p>
          <div className="flex justify-center mt-16 mb-16">
            <p className=" border-b-4 w-[40vw] border-[#2e1065]">{/*Link */}</p>
          </div>
        </div>
      </div>

      {/*------ Contact Us 2 BOXs -------*/}
      <div className="flex  flex-col lg:flex-row  lg:px-[4rem]  xl:px-[12rem] px-[1rem] xl:gap-x-10 ">
        <section className="flex justify-center  xl:w-[80vw]  bg-white w-full">
          <div className="w-full bg-[#232960] saturate-50   rounded-2xl p-[1rem] flex flex-col gap-y-4 lg:gap-y-10">
            {/*-----------------------------------------Email Box--------------------------*/}
            <div className="lg:flex  w-full  lg:w-[80%] xl:h-[12vh] lg:h-[12vh] p-6  text-white  rounded-lg    items-center lg:pb-[5rem] lg:pt-[4rem] lg:gap-x-10">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="lg:size-20 size-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                />
              </svg>

              <div className="flex-col">
                <h5 className="mb-2 tracking-tight  text-sm ">Email</h5>
                <p className="text-lg font-bold  mb-2">jjeon@jeoncpas.com</p>
                <p className=" text-sm font-semibold">
                  Email us to schedule an initial consultation or to ask any
                  questions you have.
                </p>
              </div>
            </div>
            {/*-----------------------------------------Phone Box--------------------------*/}
            <div className=" lg:flex  w-full  lg:w-[80%] xl:h-[12vh] lg:h-[12vh] p-6  text-white  rounded-lg    items-center lg:pb-[5rem] lg:pt-[4rem] lg:gap-x-10">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="lg:size-20 size-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>

              <div className="flex-col text-white">
                <h5 className="mb-2 tracking-tight  text-sm ">Phone</h5>
                <p className="text-lg font-bold  mb-2">(323)708-1555</p>
                <p className="font-semibold text-sm">
                  Call us at to schedule an initial consulation or to ask any
                  questions you have.
                </p>
              </div>
            </div>

            {/*-----------------------------------------Instagram Box--------------------------*/}
            <div className="xl:h-[12vh] text-white lg:flex w-full lg:w-[80%]  lg:h-[12vh] p-6    rounded-lg   gap-x-10 items-center lg:pb-[5rem] lg:pt-[4rem] ">
              <img
                src={Instagram_Glyph_Gradient}
                className="w-[44px] md:w-[6vw] xl:w-[3vw] lg:w-[4vw] "
              />

              <div className="flex-col">
                <h5 className="mb-2 tracking-tight  text-sm ">Instagram</h5>
                <Link to={instagramURL} target="_blank">
                  <p className="text-lg font-bold  mb-2">@jeoncpas</p>
                </Link>
                <p className="font-semibold text-sm ">
                  Follow us and stay up to date with rules and regulations in a
                  bite-sized learning format.
                </p>
              </div>
            </div>
            {/*-----------------------------------------KakaoTalk Box--------------------------*/}
            <div className="lg:flex  w-full  lg:w-[80%] xl:h-[12vh] lg:h-[12vh] p-6  text-white  rounded-lg    items-center lg:pb-[5rem] lg:pt-[4rem] lg:gap-x-10">
              <img
                src={kakaotalk_sharing_btn}
                className="w-[44px] md:w-[6vw] xl:w-[3vw] lg:w-[4vw] "
              />

              <div className="flex-col">
                <h5 className="mb-2 tracking-tight text-sm ">KakaoTalk</h5>
                <p className="text-lg font-bold mb-2">jeoncpas</p>
                <p className=" text-sm">
                  한국어로 상담 예약을 원하시거나 질문이 있으실 경우, 카카오톡
                  ID 검색 후 친구 추가 하시기 바랍니다.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/*--------------------------------------------------Layout 2--------------------------*/}
        <section className="w-full bg-white rounded-xl px-[1rem]">
          <div className="flex flex-col justify-center items-center">
            <div className="lg:px-[2rem] xl:px-[2rem] ">
              <header className="font-extrabold text-3xl text-center mt-[4rem] lg:mt-[0rem] ">
                Have Questions?
              </header>
              <p className="text-center font-thin text-base mt-[2rem] mb-[2rem]">
                Have questions about how we can assist with your specific
                matters? Fill out the form and we will be in touch shortly.
              </p>
            </div>
            {/*-----------------Email FORM SECTION--------------------------*/}
            <form
              onSubmit={sendEmailHandlerFunction}
              className="xl:flex xl:flex-col md:p-2 lg:w-[36vw] w-full"
              ref={form}
            >
              <div className="">
                <label
                  htmlFor="user_name"
                  className="leading-7 text-sm text-gray-600 "
                >
                  Full Name *
                </label>
                <input
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  required
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                />
              </div>
              <div className="">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600 "
                >
                  Email *
                </label>
                <input
                  type="email"
                  name="user_email"
                  onChange={handleChange}
                  value={formData.user_email}
                  required
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                />
              </div>
              <div className="">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600 "
                >
                  Phone *
                </label>
                <input
                  type="tel"
                  name="user_phone"
                  value={value}
                  onChange={onlyInteger}
                  required
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                />
              </div>
              {/*-----------------Company Name--------------------------*/}
              <div className="">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600 "
                >
                  Your Company Name (Optional)
                </label>

                <input
                  type="text"
                  name="user_company_name"
                  onChange={handleChange}
                  value={formData.user_company_name}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                />
              </div>

              {/*-----------------Company Size--------------------------*/}

              <div className="w-full">
                <label
                  htmlFor="user_company_size"
                  className="leading-7 text-sm text-gray-600"
                >
                  Company Size (Optional)
                </label>
                <select
                  id="user_company_size"
                  name="user_company_size"
                  value={formData.user_company_size}
                  onChange={handleChange}
                  className="flex justify-between text-gray-700 bg-gray-100 border-gray-300 border rounded focus:border-indigo-500 focus:ring-indigo-200  focus:bg-white focus:ring-2 focus:outline-none  text-sm px-2 py-2.5  items-center w-full transition-colors duration-200 ease-in-out
"
                >
                  <option value="">Select Company Size</option>
                  <option value="1-10" className="text-sm">
                    1-10 employees
                  </option>
                  <option value="11-50" className="text-sm">
                    11-50 employees
                  </option>
                  <option value="51-200" className="text-sm">
                    51-200 employees
                  </option>
                  <option value="201-1000" className="text-sm">
                    201-1000 employees
                  </option>
                  <option value="1001+" className="text-sm">
                    Enterprise (1001+ employees)
                  </option>
                </select>
              </div>
              {/*-----------------Message--------------------------*/}
              <div className="">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  className=" w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-[13rem] text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              {/*-----------------Submit Button--------------------------*/}
              <div className=" w-full">
                <button
                  type="submit"
                  className="w-full flex justify-center items-center mx-auto text-white bg-[#232960] border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  Submit
                </button>
              </div>
              {/*--------------------------------------------------------*/}
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ContactUs;
