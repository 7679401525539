import react from "react";
import AuditandAssur from "./AuditandAssur";
import Advisory from "./Advisory";
import { useEffect } from "react";

const Tax = () => {
  useEffect(() => {
    window.scrollTo({});
  }, []);

  return (
    <div className="bg-[#232960] saturate-50  h-full xl:px-[2rem]  ">
      {/* -------------------------------Audit and Asurance ------------------------*/}
      <div className="p-5 max-w-screen">
        <div className="lg:w-full lg:p-10 text-right text-white">
          <p className="lg:text-3xl font-extrabold text-3xl">Tax</p>

          <p className="mt-4 mb-6 lg:text-lg font-san font-bold text-lg lg:ml-[30rem] text-white ">
            Tax preparation ensures individuals, businesses, and nonprofits
            comply with tax obligations while maximizing deductions and credits
            to minimize tax liabilities within legal boundaries
          </p>
        </div>
        <div className=" mb-[4rem] grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 px-[2rem]">
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 border shadow-lg rounded-lg shadow-black ">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center"></div>
              <p className="font-bold text-2xl mb-[2rem]">Individual</p>
              <p className="text-base text-center  px-[2rem] ">
                We will prepare Form 1040 and relevant schedules and submit to
                IRS.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 border shadow-lg rounded-lg  shadow-black">
            <section className="p-6 px-8 text-center ">
              <div className="flex justify-center"></div>
              <p className="font-bold text-2xl mb-[2rem]">Business</p>
              <p className="text-base text-center  px-[0.5rem] ">
                We will prepare Form 1065, Form 1120-S, Form 1120-C, and
                relevant schedules and submit to IRS.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 border shadow-lg rounded-lg  shadow-black">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center"></div>
              <p className="font-bold text-2xl mb-[2rem]">
                Charity and Nonprofit
              </p>
              <p className="text-base text-center  px-[2rem] ">
                We will prepare Form 990 and relevant schedules and submit to
                IRS.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tax;
