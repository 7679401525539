import React, { useEffect } from "react";
import { useState } from "react";

import { TfiWrite } from "react-icons/tfi";
import { AiOutlineAudit } from "react-icons/ai";
import { MdQuestionAnswer } from "react-icons/md";

const Advisory = () => {
  const [isOpen, setIsOpen] = useState(null);

  return (
    <div className="bg-white text-black xl:px-[2rem]">
      {/* -------------------------------Audit and Asurance ------------------------*/}
      <div className="p-5 max-w-screen ">
        <div className="lg:w-[60vw] ">
          <h1 className="text-black lg:mt-10 mt-20  mb-6 block text-base font-semibold  font-serif"></h1>
          <p className="lg:text-3xl font-extrabold text-3xl text-black ">
            Advisory
          </p>

          <p className="pt-4 pb-[2rem] lg:pb-[4rem] lg:text-lg font-san font-semibold text-lg text-black ">
            A comprehensive advisory service helps you plan, manage, and
            optimize your financial situation under expert guidance and
            solutions tailored to your specific needs and goals.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 px-[0.9rem]">
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 lg:border-r-2 border-gray-400">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <AiOutlineAudit size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">
                Audit Preparation
              </p>
              <p className="text-base text-left pr-[1rem] ">
                We will help you prepare for your upcoming audit by reviewing
                your financial statements, internal controls, and compliance
                policies. We will identify any potential issues or risks that
                could affect your audit outcome and advise you on how to address
                them. We will also provide you with guidance on the audit
                process, expectations, and best practices. Our audit preparation
                service will help you reduce your audit stress, save time and
                money, and improve your audit quality and reliability.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 lg:border-r-2 border-gray-400">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <TfiWrite size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">
                Bookkeeping
              </p>
              <p className="text-base text-left">
                We will help you manage your daily accounting tasks, such as
                recording transactions, reconciling accounts, and preparing
                financial reports. We will ensure that your books are accurate,
                complete, and up to date, and that they comply with the relevant
                accounting standards and regulations. Our bookkeeping service
                will help you save time and resources, avoid errors and
                penalties, and make informed business decisions.
              </p>
            </section>
          </div>
          <div className="relative flex flex-col bg-clip-border bg-white text-gray-700  ">
            <section className="p-6 px-8 text-center">
              <div className="flex justify-center">
                <MdQuestionAnswer size={60} />
              </div>
              <p className="font-bold text-2xl mb-[2rem] mt-[1.6rem]">
                Management Consulting
              </p>
              <p className="text-base text-left">
                We will help you improve your business performance, strategy,
                and operations by analyzing your current situation, identifying
                your challenges and opportunities, and providing you with
                practical solutions and action plans. We will also assist you in
                implementing the changes and measuring the results. Our
                management consulting service covers various areas, such as
                business planning, organizational design, change management,
                risk management, process improvement, and investigation. Our
                management consulting service will help you achieve your goals,
                increase your competitiveness, and enhance your customer
                satisfaction.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Advisory;
