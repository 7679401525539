import React, { useEffect } from "react";
import icons8_health_500 from "./Asset/icons8_health_500.png";
import icons8_real_estate_500 from "./Asset/icons8_real_estate_500.png";
import icons8_calculator_500 from "./Asset/icons8_calculator_500.png";
import hospital from "./Asset/hospital.jpg";
import realestate from "./Asset/realestate.jpg";
import smallbusiness from "./Asset/smallbusiness.jpg";
import { useLocation } from "react-router-dom";

const Industries = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [hash]);

  return (
    <section class="container mx-auto px-8 py-8 mt-16 lg:mt-0 ">
      <h2 class="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900  lg:!text-4xl">
        Industries
      </h2>

      <div class="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-3">
        <div
          id="healthcare"
          class="flex-col bg-clip-border  bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"
        >
          <img
            src={hospital}
            alt="bg"
            class="absolute inset-0 h-full w-full object-cover object-center"
          />
          <div class="absolute inset-0 bg-black/70"></div>
          <div class="p-6 relative flex flex-col justify-end">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-white">
              Healthcare
            </h4>
            <p class="block antialiased font-sans text-base  leading-relaxed text-white my-2 ">
              The industry involves entities that provide medical care to
              patients and need accounting services to manage their revenue
              cycle, billing and collections, cost accounting, budgeting and
              forecasting, and financial reporting. We can help you improve you
              financial performance, compliance, and transparency.
            </p>
          </div>
        </div>
        <div
          id="realEstate"
          class="flex-col bg-clip-border  bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"
        >
          <img
            src={realestate}
            alt="bg"
            class="absolute inset-0 h-full w-full object-cover object-center"
          />
          <div class="absolute inset-0 bg-black/70"></div>
          <div class="p-6 relative flex flex-col justify-end">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-white">
              Real Estate
            </h4>
            <p class="block antialiased font-sans text-base  leading-relaxed text-white my-2 ">
              This industry involves the buying, selling, renting, leasing,
              developing, and managing of land and buildings for residential,
              commercial, industrial, or agricultural purposes. We can help you
              with your financial planning, valuation, taxation, auditing,
              reporting, and compliance with the various laws and regulations
              governing the industry. We can also help you optimize your cash
              flow, profitability, and growth potential.
            </p>
          </div>
        </div>
        <div
          id="smallBusiness"
          class=" flex-col bg-clip-border  bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"
        >
          <img
            src={smallbusiness}
            alt="bg"
            class="absolute inset-0 h-full w-full object-cover object-center"
          />
          <div class="absolute inset-0 bg-black/70"></div>
          <div class="p-6 relative flex flex-col justify-end">
            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-white">
              Small Business
            </h4>
            <p class="block antialiased font-sans text-base leading-relaxed text-white my-2 ">
              This industry comprises businesses that have fewer than 500
              employees and operate in various sectors, such as IT, start-up,
              retail, hospitality, manufacturing, or services. We can help small
              businesses with your bookkeeping, payroll, tax preparation,
              financial reporting, budgeting, and cash management. We can also
              help you access financing, comply with the relevant laws and
              regulations, and improve your efficiency and profitability.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Industries;
