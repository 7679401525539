import React, { useState, useEffect } from "react";
import CareersImage from "./Asset/CareersImage.jpg";
import { IoMdTime } from "react-icons/io";
import { FaRegHandshake } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";

const Careers = () => {
  const [onChangeHandler, setOnChangeHandler] = useState("");
  const onChangeTrigger = (event) => {
    setOnChangeHandler(event.target.value);
    console.log(onChangeHandler);
  };
  const textlist = [
    {
      title: "Flexible",
      icon: <IoMdTime size={90} />,
      describe: `We work wherever we want and whenever we want as long as our work is done.`,
    },
    {
      title: "Collaborative",
      icon: <FaRegHandshake size={90} />,
      describe: "We work as a team and support each other.",
    },
    {
      title: "Successful",
      icon: <BsGraphUpArrow size={90} />,
      describe: "We are financially thriving together.",
    },
  ];
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="w-screen  ">
      <div className=" ">
        <section className=" inset-0 xl:pt-16 mt-[8rem] lg:mt-[0rem]">
          <header className="text-4xl font-extrabold flex justify-center gap-3  ">
            We Are
          </header>

          <div className="flex justify-center mt-20 mb-20">
            <p className=" border-b-4 w-[40vw] border-[#2e1065]">{/*Link */}</p>
          </div>
        </section>
      </div>
      {/*------ Careers Section 2 icons+contents -------*/}
      <section className="mb-20 ">
        <div className="text-center	 flex flex-col justify-between lg:flex-row 2xl:px-[20rem] lg:px-[4rem] ">
          {textlist.map((textlist, index) => (
            <div className="mt-10 flex flex-col items-center lg:w-[30rem] ">
              <div className="mb-10 bg-[#232960] w-[12rem] h-[12rem] flex flex-col justify-center items-center lg:w-40 lg:h-40  rounded-full text-white">
                {textlist.icon}
              </div>
              <p className="text-center font-extrabold text-3xl mb-10 text-[#2e1065] ">
                {textlist.title}
              </p>
              <p className="font-bold text-xl p-5 ">{textlist.describe}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-20 ">
          <p className=" border-b-4 w-[40vw] border-[#2e1065]">{/*Link */}</p>
        </div>
      </section>
      {/*------=------------------- Careers Section------------------- -------*/}
      <section>
        <div className="flex flex-col justify-center items-center">
          <header className="font-extrabold text-3xl mb-2 text-center">
            Interested in Working with Us?
          </header>
          <p className="text-center font-thin text-base">
            Let's discuss if it's going to be a good fit! We'll be in touch
            shortly.
          </p>
          <div className="lg:w-1/2 w-full xl:px-7 px-[2.5rem]">
            <div className="">
              <label for="name" className="leading-7 text-sm text-gray-600 ">
                Full Name *
              </label>
              <input
                onChange={onChangeTrigger}
                type="text"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
              />
            </div>
            <div className="">
              <label for="name" className="leading-7 text-sm text-gray-600 ">
                Email *
              </label>
              <input
                onChange={onChangeTrigger}
                type="text"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
              />
            </div>
            <div className="">
              <label for="name" className="leading-7 text-sm text-gray-600 ">
                Phone *
              </label>
              <input
                onChange={onChangeTrigger}
                type="text"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
              />
            </div>
            <div className="">
              <label for="name" className="leading-7 text-sm text-gray-600 ">
                Experties *
              </label>
              <input
                onChange={onChangeTrigger}
                type="text"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
              />
            </div>
            <div className="">
              <label for="name" className="leading-7 text-sm text-gray-600 ">
                Years of Experience *
              </label>
              <input
                onChange={onChangeTrigger}
                type="text"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
              />
            </div>
            <div className="">
              <label for="message" className="leading-7 text-sm text-gray-600">
                Message *
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
          </div>
        </div>
        {/*-----------------Submit Button--------------------------*/}
        <div className="p-2 w-full">
          <button className="flex mx-auto text-white bg-[#232960] saturate-50  border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Submit
          </button>
        </div>
        {/*--------------------------------------------------------*/}
      </section>
      <div className="h-[10vh]"></div>
    </div>
  );
};
export default Careers;
