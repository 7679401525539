import React from "react";

import AuditandAssur from "./AuditandAssur";
import Tax from "./Tax";
import Advisory from "./Advisory";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CollectionService = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      const queryParams = new URLSearchParams(location.search);
      const section = queryParams.get("section");

      if (section) {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    const timer = setTimeout(scrollToElement, 100);

    return () => clearTimeout(timer);
  }, [location.search]);

  return (
    <div className="bg-white text-black">
      <div id="auditandassurance">
        <AuditandAssur />
      </div>
      <div id="tax">
        <Tax />
      </div>
      <div id="advisory">
        <Advisory />
      </div>
    </div>
  );
};
export default CollectionService;
