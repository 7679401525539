import react from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import pexels_fauxels_3183156 from "./Asset/pexels_fauxels_3183156.jpg";
import pexels_hillaryfox_1595390 from "./Asset/pexels_hillaryfox_1595390.jpg";
import pexels_tima_miroshnichenko_6693655 from "./Asset/pexels_tima_miroshnichenko_6693655.jpg";
import { TbListCheck } from "react-icons/tb";
import { PiListMagnifyingGlass } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
const HomeService = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const buttonHandler = () => {
    navigate("/service");
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="bg-[#232960] saturate-50  text-white">
      <div className="flex flex-col justify-center items-center gap-y-5 px-10">
        {/*------------------------------------------------*/}
        <h1 className="mt-10 text-center font-bold text-[2.5rem]  ">
          Services
        </h1>

        <h className="text-center xl:mx-[18rem]">
          We offer a range of services to help you achieve your financial and
          business goals. Whether you need audit and assurance, tax, or advisory
          services, we have the expertise and experience to deliever quality
          solutions tailored to your needs. We are committed to providing
          high-quality, professional, and personalized service to each of our
          clients.
        </h>
        <button
          onClick={buttonHandler}
          className="mb-2 mt-4 bg-white  hover:bg-blue-700 text-[#232960] font-bold py-2 px-4 rounded-full"
        >
          Read More
        </button>

        <div className="p-10 max-w-screen ">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 w-[70vw] mb-[4rem]">
            {/*------------------Audit and Assurance------------------------------*/}
            <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 rounded-lg shadow-lg shadow-gray-500/10 ">
              <section className="p-6 px-8 text-center ">
                <div className="flex justify-center">
                  <img
                    src={pexels_hillaryfox_1595390}
                    alt=""
                    className="shadow-md shadow-gray-600 rounded-lg"
                  />
                </div>
                <p className="font-bold text-2xl mt-[2rem]">
                  Audit and Assurance
                </p>
                <div className="text-center mt-[1rem] mb-[1rem]">
                  <p>Audit</p>
                  <p>Review</p>
                  <p className="">Compilation</p>
                </div>
              </section>
            </div>
            {/*--------------------------Tax--------------------------------------*/}
            <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 rounded-lg shadow-lg shadow-gray-500/10">
              <section className="p-6 px-8 text-center">
                <div className="flex justify-center">
                  <img
                    className="shadow-md shadow-gray-600 rounded-lg "
                    src={pexels_tima_miroshnichenko_6693655}
                    alt="Sunset in the mountains"
                  />
                </div>
                <p className="font-bold text-2xl mt-[2rem]">Tax</p>
                <div className="text-center mt-[1rem] ">
                  <p>Individual</p>
                  <p>Business</p>
                  <p>Charity and Nonprofit</p>
                </div>
              </section>
            </div>
            {/*--------------------------Advisory--------------------------------------*/}
            <div className="relative flex flex-col bg-clip-border bg-white text-gray-700 rounded-lg shadow-lg shadow-gray-500/10">
              <section className="p-6 px-8 text-center">
                <div className="flex justify-center">
                  <img
                    src={pexels_fauxels_3183156}
                    alt=""
                    className="shadow-md shadow-gray-600 rounded-lg"
                  />
                </div>
                <p className="font-bold text-2xl mt-[2rem]">Advisory</p>
                <p className="mt-[1rem]">Audit Preparation</p>
                <p>Bookkeeping</p>
                <p>Management Consulting</p>
              </section>
            </div>
            {/*---------------------------------------------------------------------*/}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeService;
