import react from "react";
import ScheduleIcon from "./Asset/ScheduleIcon.png";
import QnAIcon from "./Asset/QnAIcon.png";
import SignatureIcon from "./Asset/SignatureIcon.png";
import ReviewIcon from "./Asset/ReviewIcon.png";
import PaymentIcon from "./Asset/PaymentIcon.png";
import UploadDocumentsIcon from "./Asset/UploadDocumentsIcon.png";
import FinalWorkProductIcon from "./Asset/FinalWorkProductIcon.png";
import { useEffect } from "react";
const HowWeWork = () => {
  const steps = [
    {
      // number 1
      title: "Schedule an initial consultation",
      describe:
        "We will have an initial consultation to understand your needs via Teams. If you prefer an in-person meeting, we can set up an appointment to come see you at your preferred location.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z",
      icon: ScheduleIcon,
    },
    {
      //number 2
      title: "Complete the intake questionnaire",
      describe:
        "We will send you an intake questionnaire to collect basic and necessary information about your service needs.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306",
      icon: QnAIcon,
    },
    {
      //number 3
      title: "Sign the engagement letter",
      describe:
        "Once we both determin that we are a good fit together for your needs, we will send you an engagement letter for you to sign. ",
      path: "M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318 M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8",
      icon: SignatureIcon,
    },
    {
      //number 4
      title: "Review the client preparation list",
      describe:
        "After we review the intake questionnaire you completed, we will send you a preliminary client preparation list of documents we need from you.",
      path: "M7.519 5.057q.33-.527.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218 M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8",
      icon: ReviewIcon,
    },

    {
      //number 5
      title: "Upload documents to the client portal",
      describe:
        "We will invite you to the client portal where you can upload your documents.",
      path: "M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8m15 0A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-8.006 4.158c-1.57 0-2.654-.902-2.719-2.115h1.237c.14.72.832 1.031 1.529 1.031.791 0 1.57-.597 1.57-1.681 0-.967-.732-1.57-1.582-1.57-.767 0-1.242.45-1.435.808H5.445L5.791 4h4.705v1.103H6.875l-.193 2.343h.064c.17-.258.715-.68 1.611-.68 1.383 0 2.561.944 2.561 2.585 0 1.687-1.184 2.806-2.924 2.806Z",
      icon: UploadDocumentsIcon,
    },
    {
      //number 6
      title:
        "Answer questions we may have & Upload additional documents to the client portal",
      describe:
        "We will review and go over documents you provided. We may ask you questions to gain a better understanding of documents you provided and request additional documents to complete our service. You can upload them to the client portal.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.21 3.855c1.612 0 2.515.99 2.573 1.899H9.494c-.1-.358-.51-.815-1.312-.815-1.078 0-1.817 1.09-1.805 3.036h.082c.229-.545.855-1.155 1.98-1.155 1.254 0 2.508.88 2.508 2.555 0 1.77-1.218 2.783-2.847 2.783-.932 0-1.84-.328-2.409-1.254-.369-.603-.597-1.459-.597-2.642 0-3.012 1.248-4.407 3.117-4.407Zm-.099 4.008c-.92 0-1.564.65-1.564 1.576 0 1.032.703 1.635 1.558 1.635.868 0 1.553-.533 1.553-1.629 0-1.06-.744-1.582-1.547-1.582",
      icon: QnAIcon,
    },
    {
      //number 7
      title: "Review the draft work product",
      describe:
        "We will provide you with our draft work product for your review.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.37 5.11V4.001h5.308V5.15L7.42 12H6.025l3.317-6.82v-.07H5.369Z",
      icon: ReviewIcon,
    },
    {
      //number 8
      title: "Sign the representation letter",
      describe:
        "If you are satisfied with the draft work product, we will send you a representation letter for you to sign.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-5.03 1.803c0 1.394-1.218 2.355-2.988 2.355-1.763 0-2.953-.955-2.953-2.344 0-1.271.95-1.851 1.647-2.003v-.065c-.621-.193-1.33-.738-1.33-1.781 0-1.225 1.09-2.121 2.66-2.121s2.654.896 2.654 2.12c0 1.061-.738 1.595-1.336 1.782v.065c.703.152 1.647.744 1.647 1.992Zm-4.347-3.71c0 .739.586 1.255 1.383 1.255s1.377-.516 1.377-1.254c0-.733-.58-1.23-1.377-1.23s-1.383.497-1.383 1.23Zm-.281 3.645c0 .838.72 1.412 1.664 1.412.943 0 1.658-.574 1.658-1.412 0-.843-.715-1.424-1.658-1.424-.944 0-1.664.58-1.664 1.424",
      icon: SignatureIcon,
    },
    {
      // number 9
      title: "Make a payment",
      describe:
        "We will invoice you for services we provided and request payment from you.",
      path: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.223 4.146c-1.593 0-2.425-.89-2.52-1.798h1.296c.1.357.539.72 1.248.72 1.36 0 1.88-1.353 1.834-3.023h-.076c-.235.627-.873 1.184-1.934 1.184-1.395 0-2.566-.961-2.566-2.666 0-1.711 1.242-2.731 2.87-2.731 1.512 0 2.971.867 2.971 4.014 0 2.836-1.02 4.3-3.123 4.3m.118-3.972c.808 0 1.535-.528 1.535-1.594s-.668-1.676-1.56-1.676c-.838 0-1.517.616-1.517 1.659 0 1.072.708 1.61 1.54 1.61Z ",
      path2: "",
      icon: PaymentIcon,
    },
    {
      // number 10
      title: "Receive the final work product",
      describe:
        "Once we receive payment for our service, we will release the final work product to you.",
      path: "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16 ",
      path2:
        "m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05 ",
      icon: FinalWorkProductIcon,
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="lg:mt-16 mb-20 mt-32">
      <p className="h-full mb-20 text-center text-4xl font-extrabold">
        What You Can Expect
      </p>
      <div className="mt-10 2xl:ml-[10rem] 2xl:px-[10rem] xl:ml-[10rem] lg:ml-[10rem] md:ml-[1rem] md:px-[1rem] ml-[2rem]  pr-4">
        {steps.map((step, index) => (
          <div className="">
            <div key={index} className="flex justify-start mt-10 lg:ml-[6rem] ">
              <div className="">
                <svg
                  htmlFor="https://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className={`bi bi-1-circle text-[#2e1065]`}
                  viewBox="0 0 16 16"
                >
                  <path d={step.path} />
                  <path d={step.path2} />
                </svg>
              </div>
              <img
                src={step.icon}
                alt="icon"
                className="flex h-[15%] w-[15%]"
              />

              <div
                className={`flex ml-[0.96rem] px-4 lg:px-10 lg:w-[40vw] ${
                  index < 10 ? "border-l-2 border-gray-400" : ""
                } h-38`}
              >
                <div className="">
                  <p className="text-lg font-bold lg:mb-4">{step.title}</p>
                  <p className="text-sm font-semibold ">{step.describe}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default HowWeWork;
